import type {
  IHeroArticleSection,
  IHeroOverviewSection,
  IHeroProductSection
} from '~/lib/ContentfulService';
import { mapHeadline } from '~/utils/mapHeadline';
import type { HeadlineProps } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroProductSection.vue';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { isDefined } from '~/utils/guards/isDefined';
import type { Props as HeadlineModuleProps } from '@hypercodestudio/basler-components/dist/components/modules/HeadlineModule.vue';

export function mapHeroHeadline(
  heroItem: IHeroArticleSection | IHeroOverviewSection | IHeroProductSection
): HeadlineProps & HeadlineModuleProps {
  const advancedHeadline = heroItem.fields.advancedHeadline;

  if (isDefined(advancedHeadline) && !isEntryResolveError(advancedHeadline)) {
    return mapHeadline(advancedHeadline);
  }

  return {
    content: heroItem.fields.headline ?? '',
    level: 1,
    badgeText: heroItem.fields.badge?.fields?.title,
    badgeSize: heroItem.fields.badge?.fields?.size ?? 'medium',
    badgeStyle: heroItem.fields.badge?.fields?.style
  };
}
